// ** React Imports
import { useContext, useEffect } from "react";
// ** RTL hook
import { useRTL } from "../../utility/hooks/useRTL";
// ** Third Party Components
import ReactCountryFlag from "react-country-flag";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

// ** Internationalization Context
import { IntlContext } from "../../utility/context/Internationalization";

const IntlDropdown = () => {
  // ** Context
  const intlContext = useContext(IntlContext);
  const [, setIsRtl] = useRTL();

  // ** Function to switch Language
  const handleLangUpdate = (e, lang) => {
    e.preventDefault();
    intlContext.switchLanguage(lang);
    lang === "ar-001" ? setIsRtl(true) : setIsRtl(false);
  };
  useEffect(() => {
    intlContext.locale === "ar-001" ? setIsRtl(true) : setIsRtl(false);
  }, [setIsRtl, intlContext.locale]);
  return (
    <UncontrolledDropdown className="nav-item" href="/" tag="li">
      <DropdownToggle
        className="d-flex justify-content-start align-items-center"
        href="/"
        tag="a"
        onClick={(e) => e.preventDefault()}
      >
        <ReactCountryFlag
          className="mx-2"
          countryCode={
            intlContext.locale === "ar-001" ? "ma" : intlContext.locale
          }
          svg
        />
        <span>
          {intlContext.locale === "ar-001"
            ? intlContext.messages.Arabic
            : intlContext.messages.French}
        </span>
      </DropdownToggle>
      <DropdownMenu
        className="mt-0"
        {...(intlContext.messages.French !== "Francais" && { right: true })}
      >
        <DropdownItem
          id="dropdown-item-1"
          className="d-flex justify-content-start align-items-center"
          href="/"
          tag="a"
          onClick={(e) => handleLangUpdate(e, "ar-001")}
        >
          <ReactCountryFlag countryCode="ma" svg />
          <span className="mx-2">{intlContext.messages.Arabic}</span>
        </DropdownItem>
        <DropdownItem
          id="dropdown-item-2"
          className="d-flex justify-content-start align-items-center"
          href="/"
          tag="a"
          onClick={(e) => handleLangUpdate(e, "fr")}
        >
          <ReactCountryFlag countryCode="fr" svg />
          <span className="mx-2">{intlContext.messages.French}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default IntlDropdown;
