import { useState, useContext } from "react";
import {
  Collapse,
  CardBody,
  Card,
  CardHeader,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import { IntlContext } from "../utility/context/Internationalization";

import { FaChevronDown, FaChevronUp, FaQuestionCircle } from "react-icons/fa";

const Faqs = () => {
  const [collapse, setCollapse] = useState(0);
  const intlContext = useContext(IntlContext);
  const toggle = (e) => {
    let event;
    if (e.target.outerHTML.startsWith("<h4")) {
      event = e.target.parentElement.dataset.event;
    } else {
      event = e.target.dataset.event;
    }
    setCollapse(collapse === event ? 0 : event);
  };

  return (
    <section id="faqs" className="section">
      <Container>
        <Row className="justify-content-center">
          <Col lg={12}>
            <div className="title-box text-center">
              <h3 className="title-heading mt-1 mb-3">
                {intlContext.messages.Titles.S8.H1}
              </h3>
            </div>
          </Col>
          <Col xs="12" lg="9">
            {intlContext?.messages?.Faqs.map((faq, id) => {
              return (
                <Card
                  className="accordion"
                  style={{ marginBottom: "1rem" }}
                  key={`${id}`}
                >
                  <CardHeader
                    className="accordion-header"
                    onClick={toggle}
                    data-event={`${id}`}
                  >
                    <CardTitle
                      className={
                        collapse === `${id}` ? "text-primary mb-0" : "mb-0"
                      }
                    >
                      <FaQuestionCircle
                        className="icon-primary mx-2"
                        size="15"
                      />
                      <span onClick={toggle} data-event={`${id}`}>
                        {faq?.Question}
                      </span>
                    </CardTitle>
                    {collapse === `${id}` ? (
                      <FaChevronUp size={18} />
                    ) : (
                      <FaChevronDown size={18} />
                    )}
                  </CardHeader>
                  <Collapse isOpen={collapse === `${id}`}>
                    <CardBody className="p-0">
                      <p className="mb-0 mx-2 text-muted"> {faq?.Answer}</p>
                    </CardBody>
                  </Collapse>
                </Card>
              );
            })}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Faqs;
