import React, { useContext } from "react";
import { Modal, ModalBody, Col } from "reactstrap";
import messageIcon from "../../assets/images/mail.png";
import { IntlContext } from "../../utility/context/Internationalization";
export const SubscriptionModal = ({ open, toggle }) => {
  const intlContext = useContext(IntlContext);
  return (
    <Modal
      className="modal-dialog-centered modal-md"
      modalClassName="modal-success"
      isOpen={open}
      toggle={toggle}
    >
      {/* <ModalHeader className="text-center" toggle={toggle}>
        THANK YOU
      </ModalHeader> */}
      <ModalBody>
        <div className="d-flex justify-content-center align-items-center">
          <Col xs="5">
            <img
              className="text-center"
              alt="mail-icon"
              src={messageIcon}
              width="100%"
            />
          </Col>
        </div>

        <h4 className="text-center">
          {intlContext.messages.SubscriptionModal1}
        </h4>
        <h5 className="text-center">
          <small className="text-muted">
            {intlContext.messages.SubscriptionModal2}
          </small>
        </h5>
      </ModalBody>
    </Modal>
  );
};
