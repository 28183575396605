import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import {
  FaFingerprint,
  FaLock,
  FaFolderOpen,
  FaGavel,
  FaWhmcs,
  FaUsers,
  FaChartArea,
  FaRegCalendarAlt,
  FaBell,
  FaUserShield,
  FaFileAlt,
} from "react-icons/fa";
import { BsShieldFillCheck } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IntlContext } from "../utility/context/Internationalization";
export default class Services extends Component {
  static contextType = IntlContext;
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          id: "service0",
          icon: <FaUserShield className="icon-primary" size={27} />,
          title: "Contrôles d'accès/Autorisations",
          description:
            "nous vous offront  trois niveaux d'access d'utilisateurs : [administrateur, éditeur, visiteur] ainsi vous pouvez facilement gérer qui a accès à quoi, en veillant à ce que les informations sensibles ne soient accessibles qu'aux utilisateurs autorisés.",
        },
        {
          id: "service1",
          icon: <FaFolderOpen className="icon-primary" size={27} />,
          title: "Gestion Dossiers",
          description:
            "Gérez facilement toutes les informations relatives à vos dossiers(parties en conflits, pieces jointes...).",
        },
        {
          id: "service2",
          icon: <FaGavel className="icon-primary" size={27} />,
          title: "Gestion Audiences",
          description:
            "Restez au courant de tous vos audiences, et accéder à l'intégralité du dossier client dans le calendrier juridique.",
        },
        {
          id: "service3",
          icon: <FaWhmcs className="icon-primary" size={27} />,
          title: "Exécution Notification",
          description:
            "Gérer l'après le jugement et procéder à la signification des décisions de justices obtenues et Exécuter les décisions de justice.",
        },
        {
          id: "service4",
          icon: <FaUsers className="icon-primary" size={27} />,
          title: "Gestion Parties en conflit",
          description:
            "Gardez une trace de toutes vos coordonnées comme le nom, le numéro, l'adresse, l'e-mail, les notes, les tâches, les événements, etc.",
        },
        {
          id: "service6",
          icon: <FaRegCalendarAlt className="icon-primary" size={27} />,
          title: "Agendas individuels et collaboratifs",
          description:
            "Plannifiez vos rendez-vous et gérez l'avancement des procédures via l'agenda général.",
        },
        {
          id: "service7",
          icon: <FaBell className="icon-primary" size={27} />,
          title: "Notifications et alertes en temps réel",
          description:
            "Rappel des évènements à venir. Restez informé en permanence de l'activité de votre cabinet.",
        },
        {
          id: "service6",
          icon: <FaChartArea className="icon-primary" size={27} />,
          title: "Analytics",
          description:
            "Suivez facilement vos performances avec des analyses intégrées. Identifiez les opportunités pour ajuster les flux de travail et mieux allouer les ressources.",
        },
        {
          id: "service11",
          icon: <FaFileAlt className="icon-primary" size={27} />,
          title: "Modèles de Documents",
          description:
            "Vous pouvez créer et télécharger des documents basés sur les modèles de fichiers que vous avez précédemment demandés, via une interface facile à utiliser qui vous guide tout au long du processus de création ou de demande de documents, ce qui vous permet de choisir le document dans une liste d' options préétablie ou communiquez avec notre équipe technique et demandez un nouveau document en téléchargeant le formulaire de document.",
        },
      ],
      counters: [
        {
          id: "1",
          extraclass: "",
          start: 25,
          end: 49,
          title: "Sécurité",
          description: `
            Toutes vos données stockées sur nos
            serveurs sont crypté et nous utilisons également 
            le chiffrement des données en transit pour chiffrer
            le trafic réseau entre vous et les serveurs. Outre
            les données sensibles telles que l'utilisateur,
            les mots de passe sont cryptés à l'aide d'un
            cryptage de bout en bout.`,
          icon: <FaLock className="counter-value" />,
        },
        {
          id: "2",
          extraclass: "pt-3",
          start: 25,
          end: 76,
          title: "Confidentialité",
          description: `
            L'une de nos priorités est d'assurer la protection
            et la confidentialité des données. Par conséquent, 
            les données de chaque client sont stockées dans un
            bloc serveur séparé. Pour éviter les fuites 
            d'informations, toutes les données sont chiffrées
            au repos et en transit. Alors que la plupart des 
            données sensibles sont cryptées à l'aide d'un cryptage
            de bout en bout et ne sont pas accessibles même à
            notre propre personnel.`,
          icon: <FaFingerprint className="counter-value" />,
        },
        {
          id: "3",
          extraclass: "pt-3",
          start: 25,
          end: 99,
          title: "Disponibilité",
          description: `
            Hébergé dans un centre de données sécurisé. Les données sont
            protégées contre la perte et le système est conçu de manière
            à ce que même si l'un des serveurs principaux tombait en panne,
            d'autres serveurs prendraient le relais pour assurer une 
            disponibilité 24h/24 et 7j/7.
          `,
          icon: <BsShieldFillCheck className="counter-value" />,
        },
      ],
    };
  }
  componentDidMount() {
    this.setState({
      services: [
        {
          id: "service0",
          icon: <FaUserShield className="icon-primary" size={27} />,
          title: this.context.messages.Features.F000.Name,
          description: this.context.messages.Features.F000.Description,
        },
        {
          id: "service1",
          icon: <FaFolderOpen className="icon-primary" size={27} />,
          title: this.context.messages.Features.F005.Name,
          description: this.context.messages.Features.F005.Description,
        },
        {
          id: "service2",
          icon: <FaGavel className="icon-primary" size={27} />,
          title: this.context.messages.Features.F004.Name,
          description: this.context.messages.Features.F004.Description,
        },
        {
          id: "service3",
          icon: <FaWhmcs className="icon-primary" size={27} />,
          title: this.context.messages.Features.F006.Name,
          description: this.context.messages.Features.F006.Description,
        },
        {
          id: "service4",
          icon: <FaUsers className="icon-primary" size={27} />,
          title: this.context.messages.Features.F007.Name,
          description: this.context.messages.Features.F007.Description,
        },
        {
          id: "service6",
          icon: <FaRegCalendarAlt className="icon-primary" size={27} />,
          title: this.context.messages.Features.F008.Name,
          description: this.context.messages.Features.F008.Description,
        },
        {
          id: "service7",
          icon: <FaBell className="icon-primary" size={27} />,
          title: this.context.messages.Features.F009.Name,
          description: this.context.messages.Features.F009.Description,
        },
        {
          id: "service6",
          icon: <FaChartArea className="icon-primary" size={27} />,
          title: this.context.messages.Features.F010.Name,
          description: this.context.messages.Features.F010.Description,
        },
        {
          id: "service11",
          icon: <FaFileAlt className="icon-primary" size={27} />,
          title: this.context.messages.Features.F011.Name,
          description: this.context.messages.Features.F011.Description,
        },
      ],
    });
  }
  render() {
    return (
      <React.Fragment>
        <section className="section bg-services" id="services">
          <Container>
            <Row>
              <Col lg="12">
                <div className="title-box text-center">
                  <h3 className="title-heading mt-1">
                    {this.context.messages.Titles.S2.H1}
                  </h3>
                  <p className="text-muted f-17 mt-3">
                    {this.context.messages.Titles.S2.H2}
                  </p>

                  {/* <img src={HomeUrl} height="15" className="mt-3" alt="" /> */}
                </div>
              </Col>
            </Row>
            <Row className="mt-1 pt-1 justify-content-center mx-3">
              {/* Render Footer Link */}
              {this.state.services.map((item, key) => (
                <Col
                  className=" d-flex align-items-stretch"
                  lg="4"
                  md="6"
                  sm="12"
                  key={key}
                >
                  <div className="services-box  pt-1 pb-3 px-4 mt-4 bg-soft2-primary">
                    <div className="services-icon ">{item.icon}</div>
                    <span>
                      <h5 className="text-start">{item.title}</h5>
                    </span>
                    <p className="text-muted mt-3">{item.description}</p>
                    {/* 
                    <div className="mt-3">
                      <Link to="#" className="text-primary f-16">
                        {this.context.messages.More}
                        {this.context.messages.Arabic === "Arab" ? (
                          <i className="mdi mdi-arrow-right ml-1"></i>
                        ) : (
                          <i className="mdi mdi-arrow-left ml-1"></i>
                        )}
                      </Link>
                    </div> */}
                  </div>
                </Col>
              ))}
            </Row>
            {/* <Row className="align-items-start mt-5 pt-4" id="counter">
              <Col lg={4}>
                <div className="pr-4 mt-4">
                  <p className="text-uppercase">POURQUOI NOUS CHOISIR </p>
                  <h3>Conçu pour la sécurité et l'évolutivité !</h3>
                  <p className="text-muted mt-3">
                    Ayez l'esprit tranquille grâce à une solution personnalisé,
                    sécurisé et adapté à l'ensemble de vos besoins.
                  </p>
                  <div className="mt-4 pt-1">
                    <Link to="#" className="btn btn-outline-primary">
                      Découvrir plus
                    </Link>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="counter-box">
                  {this.state.counters.map((counteritem, key) => (
                    <div
                      className={counteritem.id !== "1" ? "mt-4 pt-3" : "mt-4"}
                      key={key}
                    >
                      <div className="media">
                        <div className="count-box bg-soft-primary text-center">
                          <h3 className="counter-count mb-1 text-primary">
                            {counteritem.icon}
                          </h3>
                          <p className="text-uppercase text-muted mb-0 f-14">
                            {counteritem.title}{" "}
                          </p>
                        </div>
                        <div className="media-body pl-4">
                          <p className="text-muted mb-0">
                            {counteritem.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row> */}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
