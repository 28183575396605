export const scrollToElement = (element) => {
  const elementToScrollTo = document.querySelector(element);
  // Scroll to the element
  if (elementToScrollTo) {
    elementToScrollTo.scrollIntoView?.({
      behavior: "smooth",
      block: "center",
    });
  }
};
export const analyticEvent = (category, action) => {
  window.gtag("event", action, {
    event_category: category,
  });
};
