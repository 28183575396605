import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Button, Input } from "reactstrap";
import axios from "axios";

import FooterLink from "../Footer/Footer_link";

import Logo from "../../assets/images/logo.png";
import { IntlContext } from "../../utility/context/Internationalization";
import { SubscriptionModal } from "./SubscriptionModal";
import { analyticEvent } from "../../utility/helpers";

class Footer extends Component {
  static contextType = IntlContext;
  constructor(props) {
    super(props);
    this.state = {
      links: [
        {
          id: 1,
          title: "Liens utiles",
          child: [
            { title: "Services", link: "#" },
            { title: "FAQ", link: "#" },
            { title: "Contact", link: "#" },
          ],
        },
      ],
      open: false,
      subscriber: "",
    };
    this.handleSubscribe = this.handleSubscribe.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }
  handleSubscribe(event) {
    event.preventDefault();
    Promise.any([
      axios.post(`https://demo-api.avocatelegant.com/api/v1/demandes`, {
        firstname: this.state.subscriber.split("@")[0],
        lastname: this.state.subscriber.split("@")[0],
        subject: "Newsletter subscription",
        email: this.state.subscriber,
        content: this.state.subscriber,
      }),
      axios.post(`https://api.avocatelegant.com/api/v1/demandes`, {
        firstname: this.state.subscriber.split("@")[0],
        lastname: this.state.subscriber.split("@")[0],
        subject: "Newsletter subscription",
        email: this.state.subscriber,
        content: this.state.subscriber,
      }),
    ])
      .then((res) => {
        this.toggle();
        analyticEvent("Newsletter subscription", "success: sub");
      })
      .catch((err) => {
        analyticEvent("Newsletter subscription", "failure: sub");
        console.log(err);
      });
  }
  render() {
    return (
      <React.Fragment>
        {/* Footer Start */}
        <footer className="section bg-light bg-footer pb-5">
          <Container>
            <Row>
              <Col lg={4}>
                <div className="footer-info mt-4">
                  <div className="img-container">
                    <img src={Logo} alt="" height="72" />
                  </div>
                  <p className="text-muted mt-4 mb-2">
                    {this.context.messages.AppDesc}
                  </p>
                </div>
              </Col>
              <Col lg={5}>
                <div className="footer-info mt-4">
                  <h5 className="f-20">{this.context.messages.BeInTouch}</h5>

                  {/* <Row className="mt-4 mb-2 text-dark">
                    <Col sm="3" className="text-muted">
                      {this.context.messages.Address}
                      {":"}
                    </Col>
                    <Col sm="9">
                      <span dir="ltr" className="font-weight-bold d-block">
                        Casablanca 20190 Maroc
                      </span>
                    </Col>
                  </Row> */}

                  {/* <Row className="text-dark mt-4 mb-2">
                    <Col className="text-muted" sm="3">
                      {this.context.messages.Tél}
                      {":"}
                    </Col>
                    <Col className="phone-number font-weight-bold" sm="9">
                      <p>+212 06 63 05 17 49 </p>
                    </Col>
                  </Row> */}

                  <Row className="text-dark mt-4 mb-2">
                    <Col className="text-muted" sm="3">
                      {this.context.messages.Email}
                      {":"}
                    </Col>
                    <Col sm="9">
                      <p className="font-weight-bold">
                        contact@avocatelegant.com
                      </p>
                    </Col>
                  </Row>

                  <div className="team-social mt-4 pt-2 text-center">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item mx-1">
                        <Link to="#" className="text-reset">
                          <i className="mdi mdi-linkedin"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item mx-1">
                        <Link to="#" className="text-reset">
                          <i className="mdi mdi-twitter"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="mt-4">
                  <h5 className="f-20">{this.context.messages.StayInformed}</h5>
                  <div className="subscribe mt-4 pt-1">
                    <Form onSubmit={this.handleSubscribe}>
                      <Input
                        className="pr-4"
                        placeholder={this.context.messages.Email}
                        type="email"
                        onChange={(event) => {
                          this.setState({ subscriber: event.target.value });
                        }}
                        required
                        style={{ height: "auto" }}
                      />
                      <Button color="primary" className="btn btn-primary">
                        <i
                          className={
                            this.context.messages.Arabic === "Arab"
                              ? "mdi mdi-send"
                              : "mdi mdi-send mdi-rotate-180"
                          }
                        ></i>
                      </Button>
                      <p className="px-2 small text-muted">
                        {this.context.messages.EmailNotShared}
                      </p>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
            <hr className="my-5" />
            {/* Render Footer Link End */}
            <FooterLink />
          </Container>
          <SubscriptionModal open={this.state.open} toggle={this.toggle} />
        </footer>
        {/* Footer End */}
      </React.Fragment>
    );
  }
}

export default Footer;
