// ** React Imports
import { useState, createContext } from "react";

// ** Intl Provider Import
import { IntlProvider } from "react-intl";

// ** Core Language Data
//import messagesFr from '@assets/data/locales/fr.json'

// ** User Language Data
import userMessagesFr from "../../assets/data/locales/fr.json";
import userMessagesMa from "../../assets/data/locales/ma.json";
// ** Menu msg obj
const menuMessages = {
  "ar-001": { ...userMessagesMa },
  fr: { ...userMessagesFr },
};

// ** Create Context
const Context = createContext();

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const [locale, setLocale] = useState("ar-001");
  const [messages, setMessages] = useState(menuMessages["ar-001"]);

  // ** Switches Language
  const switchLanguage = (lang) => {
    setLocale(lang);
    setMessages(menuMessages[lang]);
  };

  return (
    <Context.Provider value={{ locale, switchLanguage, messages }}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale="ar-001"
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export { IntlProviderWrapper, Context as IntlContext };
