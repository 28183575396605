//** React Imports
import { useEffect, useState } from "react";

export const useRTL = () => {
  // ** Store Vars
  const [isRtl, setValue] = useState(true);

  useEffect(() => {
    // ** Get HTML Tag
    const element = document.getElementsByTagName("html")[0];

    // ** If isRTL then add attr dir='rtl' with HTML else attr dir='ltr'
    if (isRtl) {
      element.setAttribute("dir", "rtl");
      element.setAttribute("lang", "ar");
    } else {
      element.setAttribute("dir", "ltr");
      element.setAttribute("lang", "fr");
    }
  }, [isRtl]);

  return [isRtl, setValue];
};
