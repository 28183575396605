import React, { Component, useContext } from "react";
import axios from "axios";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
} from "reactstrap";
import messageIcon from "../assets/images/mail.png";
import Feature from "../assets/images/features/img-3.png";
import { IntlContext } from "../utility/context/Internationalization";
import { analyticEvent } from "../utility/helpers";
import { FaWhatsapp } from "react-icons/fa";
const MessageModal = ({ open, toggle, fullName, subject }) => {
  const intlContext = useContext(IntlContext);
  return (
    <Modal
      className="modal-dialog-centered modal-md"
      modalClassName="modal-success"
      isOpen={open}
      toggle={toggle}
    >
      <ModalBody>
        <div className="d-flex justify-content-center align-items-center">
          <Col xs="5">
            <img
              className="text-center"
              alt="mail-icon"
              src={messageIcon}
              width="100%"
            />
          </Col>
        </div>

        <h4 className="text-center">{`${intlContext.messages.MessageModal1} ${fullName}`}</h4>
        <h5 className="text-center">
          <small className="text-muted">
            {subject === "طلب تجربة مجانية" ||
            subject === "Demander un essai gratuit"
              ? intlContext.messages.MessageModal3
              : subject === "أريد الإشتراك" || subject === "Je veux m'abonner"
              ? intlContext.messages.MessageModal4
              : intlContext.messages.MessageModal2}
          </small>
        </h5>
      </ModalBody>
    </Modal>
  );
};

export default class Contact extends Component {
  static contextType = IntlContext;
  constructor() {
    super();
    this.state = {
      firstname: "",
      lastname: "",
      subject: "",
      message: "",
      email: "",
      open: false,
      phone: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      open: !this.state.open,
      ...(this.state.open && {
        firstname: "",
        lastname: "",
        message: "",
        email: "",
        subject: "",
      }),
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    Promise.any([
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/demandes`, {
        ...this.state,
        content: `${this.state.message} (${this.state.phone})`,
        ...(this.state.message === "" && {
          content: `${this.state.message} (${this.state.phone})`,
        }),
      }),
      axios.post(`${process.env.REACT_APP_DEMO_API_BASE_URL}/api/v1/demandes`, {
        ...this.state,
        content: `${this.state.message} (${this.state.phone})`,
        ...(this.state.message === "" && {
          content: `${this.state.message} (${this.state.phone})`,
        }),
      }),
    ])
      .then((res) => {
        //**googole analytics event */
        analyticEvent("contact", this.state.subject);
        this.toggle();
      })
      .catch((err) => {
        console.log(err);
        analyticEvent("contact", `failure: ${this.state.subject}`);
      });
  }
  render() {
    return (
      <React.Fragment>
        <section className="section mb-4" id="contact">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="title-box text-center">
                  <h3 className="title-heading mt-1">
                    {" "}
                    {this.context.messages.Titles.S7.H1}
                  </h3>
                  <p className="text-muted f-17 mt-3">
                    {this.context.messages.Titles.S7.H2}
                  </p>
                  {/* <img src={HomeUrl} height="15" className="mt-3" alt="" /> */}
                </div>
              </Col>
            </Row>
            <Row className="mt-1 pt-1">
              <Col lg={6}>
                <div className="mt-4 home-img text-center">
                  <div className="animation-2"></div>
                  <div className="animation-3"></div>
                  <img src={Feature} className="img-fluid" alt="" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="custom-form mt-4">
                  <div id="message"></div>
                  <Form
                    onSubmit={this.handleSubmit}
                    name="contact-form"
                    id="contact-form"
                  >
                    <Row>
                      <Col lg={6}>
                        <FormGroup className="mt-3">
                          <Label htmlFor="firstname" className="contact-lable">
                            <span className="text-danger">*</span>{" "}
                            {this.context.messages.FirstName}
                          </Label>
                          <Input
                            name="firstname"
                            value={this.state.firstName}
                            onChange={(e) =>
                              this.setState({ firstname: e.target.value })
                            }
                            required
                            id="firstname"
                            className="form-control"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup className="mt-3">
                          <Label htmlFor="lastname" className="contact-lable">
                            <span className="text-danger">*</span>{" "}
                            {this.context.messages.LastName}
                          </Label>
                          <Input
                            name="lastname"
                            value={this.state.lastname}
                            onChange={(e) =>
                              this.setState({ lastname: e.target.value })
                            }
                            required
                            id="lastname"
                            className="form-control"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className="mt-3">
                          <Label htmlFor="email" className="contact-lable">
                            <span className="text-danger">*</span>{" "}
                            {this.context.messages.Email}
                          </Label>
                          <Input
                            name="email"
                            value={this.state.email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                            required
                            id="email"
                            className="form-control"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup className="mt-3">
                          <Label htmlFor="phone" className="contact-lable">
                            <span className="text-danger">*</span>
                            {
                              <FaWhatsapp
                                className="med-icon-success "
                                size="27"
                              />
                            }
                          </Label>
                          <Input
                            name="phone"
                            value={this.state.phone}
                            onChange={(e) =>
                              this.setState({ phone: e.target.value })
                            }
                            required
                            id="phone"
                            className="form-control"
                            type="tel"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormGroup className="mt-3">
                          <Label htmlFor="subject" className="contact-lable">
                            <span className="text-danger">*</span>{" "}
                            {this.context.messages.Subject}
                          </Label>
                          <Input
                            name="subject"
                            value={this.state.subject}
                            list={`subjects-list`}
                            autoComplete="off"
                            onChange={(e) =>
                              this.setState({ subject: e.target.value })
                            }
                            required
                            id="subject"
                            className="form-control"
                            type="text"
                          />
                          <datalist id={`subjects-list`}>
                            {this.context.messages.Subjects.map((subject) => (
                              <option key={subject} value={subject} />
                            ))}
                          </datalist>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormGroup className="mt-3">
                          <Label htmlFor="comments" className="contact-lable">
                            {this.context.messages.YourMessage}
                          </Label>
                          <Input
                            type="textarea"
                            value={this.state.message}
                            onChange={(e) =>
                              this.setState({ message: e.target.value })
                            }
                            name="comments"
                            id="comments"
                            rows="3"
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} className="mt-3 text-right">
                        <Input
                          id="submit"
                          name="send"
                          color="primary"
                          className="submitBnt btn btn-primary btn-round"
                          value={this.context.messages.Send}
                          type="submit"
                          style={{ width: "auto", color: "#fff" }}
                        />{" "}
                        <div id="simple-msg"></div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
          <MessageModal
            fullName={`${this.state.firstname} ${this.state.lastname}`}
            subject={this.state.subject}
            open={this.state.open}
            toggle={this.toggle}
          />
        </section>
      </React.Fragment>
    );
  }
}
