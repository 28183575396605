import React, { Component } from "react";
import { Container, Row, Col, Button, Modal, ModalBody } from "reactstrap";

import "../../../node_modules/react-modal-video/scss/modal-video.scss";

import HomeUrl from "../../assets/images/home-border.png";
import { IntlContext } from "../../utility/context/Internationalization";
import Preview from "../../assets/images/features/app-preview.png";
import ModalImage from "react-modal-image";
import { analyticEvent, scrollToElement } from "../../utility/helpers";
import { FaCheck } from "react-icons/fa";
import { BsDatabaseFillLock, BsHeadset } from "react-icons/bs";
import { FaFilterCircleDollar } from "react-icons/fa6";

import { GiFamilyTree } from "react-icons/gi";
import poster from "./../../assets/images/poster.png";

class Section extends Component {
  static contextType = IntlContext;
  constructor() {
    super();
    this.state = {
      isOpen: false,
      photoIndex: 0,
      videoModalOpen: false,
      startUpload: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }
  closeLightbox() {
    this.setState({ isOpen: false });
  }
  componentDidMount() {
    setTimeout(() => this.setState({ startUpload: true }), 9000);
    let vid = document.getElementById("preview-video");
    if (vid) {
      vid.addEventListener("play", function () {
        vid.controls = true;
      });
      vid.volume -= 0.8;
    }
  }
  render() {
    return (
      <React.Fragment>
        <section className="bg-home" id="home">
          <Container>
            <Row className="align-items-center">
              <Col md="12" lg="6">
                <div className="home-content">
                  <p className="mb-0 text-primary font-weight-bold h2">
                    AVOCAT ÉLÉGANT
                  </p>
                  <div className="d-flex justify-content-start align-items-start">
                    <img src={HomeUrl} alt="" />
                  </div>
                  <h4 className="home-title mt-1 mt-sm-2">
                    {this.context.messages.Titles.S1.H11} <br />
                    {this.context.messages.Titles.S1.H12}
                  </h4>
                  <p className="mt-2 f-20">
                    {this.context.messages.Titles.S1.H2}
                  </p>
                  <div className="button-container mt-2">
                    <Button
                      onClick={() => {
                        scrollToElement("#contact");
                        const formInput = document.getElementById("firstname");
                        if (formInput) {
                          formInput.focus();
                        }
                      }}
                      className="btn font-weight-bolder btn-success rounded-pill mr-3 mb-1 d-inline-block"
                    >
                      {this.context.messages.DemoRequest}
                    </Button>
                  </div>
                  <div className="free-try text-muted mt-2">
                    <p className="text-center">
                      <FaCheck color="success" className="text-success mx-1" />
                      <span>{this.context.messages.CreditCardNotRequired}</span>
                    </p>
                    <p className="text-center px-2">
                      <FaCheck color="success" className="text-success mx-1" />
                      <span>{this.context.messages.FreeTry}</span>
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="12" lg="6">
                <video
                  width="100%"
                  autoPlay={true}
                  playsInline=""
                  muted={false}
                  loop="loop"
                  poster={poster}
                  className="med-cursor-pointer"
                  id="preview-video"
                  onClick={() => {
                    analyticEvent("video", "zoom-in");
                  }}
                >
                  {this.state.startUpload && (
                    <source
                      type="video/mp4"
                      src={process.env.REACT_APP_DEMO_VIDEO_URL}
                    />
                  )}
                  <ModalImage
                    className="w-100 right"
                    small={Preview}
                    large={Preview}
                    hideZoom={false}
                    hideDownload={true}
                    imageBackgroundColor="transparent"
                    alt=" "
                    onClose={this.closeLightbox}
                  />
                </video>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section bg-services">
          <Row className="d-flex justify-content-center mx-3">
            <div className="benefits-box px-3 pb-3 mb-4">
              <GiFamilyTree className="icon-secondary" size={63} />

              <h5 className="text-start text-center px-5">
                {this.context.messages?.MainSectionWhy?.T001}
              </h5>
              <p className="text-muted">
                {this.context.messages?.MainSectionWhy?.D001}
              </p>
            </div>
            <div className="benefits-box px-3 pb-3 mb-4">
              <BsHeadset className="icon-secondary" size={63} />

              <h5 className="text-start text-center px-5">
                {" "}
                {this.context.messages?.MainSectionWhy?.T002}
              </h5>
              <p className="text-muted">
                {this.context.messages?.MainSectionWhy?.D002}
              </p>
            </div>
            <div className="benefits-box pb-3 mb-4">
              <FaFilterCircleDollar className="icon-secondary" size={63} />
              <h5 className="text-start text-center">
                {" "}
                {this.context.messages?.MainSectionWhy?.T004}
              </h5>
              <p className="text-muted">
                {this.context.messages?.MainSectionWhy?.D004}
              </p>
            </div>
            <div className="benefits-box pb-3 mb-4">
              <BsDatabaseFillLock className="icon-secondary" size={63} />
              <h5 className="text-start text-center">
                {this.context.messages?.MainSectionWhy?.T003}
              </h5>
              <p
                className="text-muted"
                dangerouslySetInnerHTML={{
                  __html: this.context.messages?.MainSectionWhy?.D003,
                }}
              ></p>
            </div>
          </Row>
          <Modal
            className="modal-dialog-centered modal-lg p-0"
            isOpen={this.state.videoModalOpen}
            toggle={() => {
              analyticEvent("video", "zoom-out");
              this.setState({ videoModalOpen: false });
              let vid = document.getElementById("preview-video");
              if (vid) vid.play();
            }}
          >
            <ModalBody className="d-flex align-items-stretch p-0">
              <video
                width="100%"
                autoPlay={true}
                playsInline=""
                muted={false}
                loop="loop"
                poster={poster}
              >
                <source
                  type="video/mp4"
                  src={process.env.REACT_APP_DEMO_VIDEO_URL}
                />
                Your browser does not support the video tag.
              </video>
            </ModalBody>
          </Modal>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
