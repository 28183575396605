import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { FaFingerprint, FaLock } from "react-icons/fa";
import { Ri24HoursLine } from "react-icons/ri";
import { IntlContext } from "../utility/context/Internationalization";

export default class Securité extends Component {
  static contextType = IntlContext;

  constructor(props) {
    super(props);
    this.state = {
      counters: [
        {
          id: "1",
          extraclass: "",
          start: 25,
          end: 49,
          title: "Sécurité",
          description: `
            Toutes vos données stockées sur nos
            serveurs sont crypté et nous utilisons également 
            le chiffrement des données en transit pour chiffrer
            le trafic réseau entre vous et les serveurs. Outre
            les données sensibles telles que l'utilisateur,
            les mots de passe sont cryptés à l'aide d'un
            cryptage de bout en bout.`,
          icon: <FaLock className="counter-value" />,
        },
        {
          id: "2",
          extraclass: "pt-3",
          start: 25,
          end: 76,
          title: "Confidentialité",
          description: `
            L'une de nos priorités est d'assurer la protection
            et la confidentialité des données. Par conséquent, 
            les données de chaque client sont stockées dans un
            bloc serveur séparé. Pour éviter les fuites 
            d'informations, toutes les données sont chiffrées
            au repos et en transit. Alors que la plupart des 
            données sensibles sont cryptées à l'aide d'un cryptage
            de bout en bout et ne sont pas accessibles même à
            notre propre personnel.`,
          icon: <FaFingerprint className="counter-value" />,
        },
        {
          id: "3",
          extraclass: "pt-3",
          start: 25,
          end: 99,
          title: "Disponibilité",
          description: `
            Hébergé dans un centre de données sécurisé. Les données sont
            protégées contre la perte et le système est conçu de manière
            à ce que même si l'un des serveurs principaux tombait en panne,
            d'autres serveurs prendraient le relais pour assurer une 
            disponibilité 24h/24 et 7j/7.
          `,
          icon: <Ri24HoursLine className="counter-value" />,
        },
      ],
    };
  }
  componentDidMount() {
    this.setState({
      counters: [
        {
          id: "1",
          extraclass: "",
          start: 25,
          end: 49,
          title: "Sécurité",
          description: this.context.messages.SecurityDescription,
          icon: <FaLock className="counter-value" />,
        },
        {
          id: "2",
          extraclass: "pt-3",
          start: 25,
          end: 76,
          title: "Confidentialité",
          description: this.context.messages.ConfidentialitéDescription,
          icon: <FaFingerprint className="counter-value" />,
        },
        {
          id: "3",
          extraclass: "pt-3",
          start: 25,
          end: 99,
          title: "Disponibilité",
          description: this.context.messages.DisponibilitéDescription,
          icon: <Ri24HoursLine className="counter-value" />,
        },
      ],
    });
  }
  render() {
    return (
      <React.Fragment>
        <section className="section bg-services" id="securité">
          <Container>
            <Row>
              <Col lg="12">
                <div className="title-box text-center">
                  <h3 className="title-heading mt-1">
                    {/* Application sécurisé concu pour satisfaire et evoluer avec
                    toutes vos besoin
                     */}
                    {this.context.messages.Titles.S3.H1}
                  </h3>
                  <p className="text-muted f-17 mt-3">
                    {this.context.messages.Titles.S3.H2}
                  </p>

                  {/* <img src={HomeUrl} height="15" className="mt-3" alt="" /> */}
                </div>
              </Col>
              <Col lg={12}>
                <div className="counter-box">
                  {this.state.counters.map((counteritem, key) => (
                    <div
                      className={counteritem.id !== "1" ? "mt-4 pt-3" : "mt-4"}
                      key={key}
                    >
                      <div className="security-item">
                        <div className="count-box wrap-div  bg-soft-primary text-center">
                          <h3 className="counter-count mb-1 text-primary">
                            {counteritem.icon}
                          </h3>
                          <p className="text-uppercase text-muted count-plus mb-0 f-14">
                            {counteritem.title}{" "}
                          </p>
                        </div>
                        <p className="text-muted med-p-wrap text-justify mb-0">
                          {counteritem.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
