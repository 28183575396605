import React, { Component } from "react";
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from "reactstrap";

// Import Logo
import logo from "../../assets/images/logo.png";

import ScrollspyNav from "./Scrollspy";
import IntlDropdown from "./IntlDropdown";
import { analyticEvent } from "../../utility/helpers";

class NavbarPage extends Component {
  // render() {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    let targetId = this.props.navItems.map((item) => {
      return item.idnm;
    });
    return (
      <Navbar
        expand="lg"
        fixed={this.props.top === true ? "top" : ""}
        className={this.props.navClass + " navbar-custom sticky sticky-dark"}
        id="navbar"
      >
        <Container>
          {/* LOGO */}
          <NavbarBrand className="navbar-brand logo text-uppercase" href="/">
            <img src={logo} alt="" height="72" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle}>
            <i className="mdi mdi-menu"></i>
          </NavbarToggler>
          <Collapse
            id="navbarCollapse"
            isOpen={this.state.isOpenMenu}
            className=" navbar-collapse"
          >
            <ScrollspyNav
              scrollTargetIds={targetId}
              scrollDuration="300"
              headerBackground="true"
              activeNavClass="active"
              className="navbar-collapse"
            >
              <Nav
                className="navbar-nav ml-auto navbar-center"
                id="navbar-navlist"
              >
                {this.props.navItems.map((item, key) => (
                  <NavItem
                    key={key}
                    onClick={() => {
                      //**googole analytics event */
                      analyticEvent("goto_section", item.idnm);
                    }}
                    className={item.idnm === "home" ? "active" : ""}
                  >
                    <NavLink
                      className={item.idnm === "home" ? "active" : ""}
                      href={"#" + item.idnm}
                    >
                      {item.navheading}
                    </NavLink>
                  </NavItem>
                ))}
                <IntlDropdown />
              </Nav>
            </ScrollspyNav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}
// }
export default NavbarPage;
