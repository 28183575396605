import React, { Component, useState } from "react";
import { Col, Container, Row, Button, Tooltip } from "reactstrap";
import { IntlContext } from "../utility/context/Internationalization";
import { scrollToElement } from "../utility/helpers";
import Preview from "../assets/images/features/app-preview.png";
import ModalImage from "react-modal-image";

function TooltipItem(props) {
  const { content, id } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Tooltip
      placement={"top"}
      isOpen={tooltipOpen}
      target={id}
      toggle={toggle}
      className="tooltip-info"
    >
      {content}
    </Tooltip>
  );
}
export default class Pricing extends Component {
  static contextType = IntlContext;
  constructor(props) {
    super(props);
    this.state = {
      pricing: [
        {
          id: 2,
          title: "BASIC",
          titlePrimary: true,
          description:
            "Semper urna veal tempus pharetra elit habisse platea dictumst.",
          icon: "mdi-account-multiple text-primary",
          regularprice: "200",
          saleprice: "200",
          pricingTag: false,
          child: [
            {
              btitle: "Pack Standard",
              title: "Complet",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Nom de domaine",
              title: "personnalisé",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Gestion de Tâche",
              title: "collaborative",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Aaaaaa",
              title: "Aaaaaa Aaaaaa",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Aaaaaa",
              title: "Aaaaaa Aaaaaa",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Aaaaaa",
              title: "Aaaaaa Aaaaaa",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Aaaaaa",
              title: "Aaaaaa Aaaaaa",
              icon: "mdi-checkbox-marked-circle",
            },
          ],
        },
        {
          id: 1,
          title: "STANDARD",
          description:
            "Semper urna veal tempus pharetra elit habisse platea dictumst.",
          icon: "mdi-account",
          titlePrimary: false,
          regularprice: "249",
          saleprice: "249",
          pricingTag: true,
          child: [
            {
              btitle: "Pack Services",
              title: "Complet",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "3 Utilisateurs",
              title: "avec autorisations",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "4 GB",
              title: "de Stockage de données",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "15 GB",
              title: "de Stockage de documents",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Modèles de Documents sur demande",
              title: "Illimité",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Sous Domaine",
              title: ".avocat-élégant.com",
              icon: "mdi-checkbox-marked-circle",
              // icon: "mdi-close-circle text-danger",
            },
            {
              btitle: "Nom de domaine",
              title: "personnalisé",
              icon: "mdi-checkbox-marked-circle",
              //icon: "mdi-close-circle text-danger",
            },
          ],
        },
        {
          id: 3,
          title: "PREMIUM",
          description:
            "Semper urna veal tempus pharetra elit habisse platea dictumst.",
          titlePrimary: false,
          icon: "mdi-account-multiple-plus",
          regularprice: "499",
          saleprice: "499",
          pricingTag: false,
          child: [
            {
              btitle: "Pack Premium",
              title: "Complet",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Aaaaaa",
              title: "Aaaaaa Aaaaaa",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Aaaaaa",
              title: "Aaaaaa Aaaaaa",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Aaaaaa",
              title: "Aaaaaa Aaaaaa",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Aaaaaa",
              title: "Aaaaaa Aaaaaa",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Aaaaaa",
              title: "Aaaaaa Aaaaaa",
              icon: "mdi-checkbox-marked-circle",
            },
          ],
        },
      ],
      duration: "monthly",
    };
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount() {
    this.setState({
      pricing: [
        {
          id: 2,
          title: "BASIC",
          titlePrimary: true,
          description:
            "Semper urna veal tempus pharetra elit habisse platea dictumst.",
          icon: "mdi-account-multiple text-primary",
          regularprice: "200",
          saleprice: "300",
          pricingTag: false,
          child: [
            {
              btitle: this.context.messages?.PlanFeatures?.Basic?.A001,
              title: this.context.messages?.PlanFeatures?.Basic?.B001,
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: this.context.messages?.PlanFeatures?.Basic?.A002,
              title: this.context.messages?.PlanFeatures?.Basic?.B002,
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: this.context.messages?.PlanFeatures?.Basic?.A003,
              title: this.context.messages?.PlanFeatures?.Basic?.B003,
              icon: "mdi-checkbox-marked-circle",
              tooltipText: this.context.messages?.PlanFeatures?.Standard?.C003,
            },
            {
              btitle: this.context.messages?.PlanFeatures?.Basic?.A004,
              title: this.context.messages?.PlanFeatures?.Basic?.B004,
              icon: "mdi-checkbox-marked-circle",
              tooltipText: this.context.messages?.PlanFeatures?.Standard?.C004,
            },
            {
              btitle: this.context.messages?.PlanFeatures?.Basic?.A005,
              title: this.context.messages?.PlanFeatures?.Basic?.B005,
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: this.context.messages?.PlanFeatures?.Basic?.A006,
              title: this.context.messages?.PlanFeatures?.Basic?.B006,
              icon: "mdi-checkbox-marked-circle",
              // icon: "mdi-close-circle text-danger",
            },
            {
              btitle: "",
              title: "",
              icon: "",
            },
          ],
        },
        {
          id: 1,
          title: "STANDARD",
          description:
            "Semper urna veal tempus pharetra elit habisse platea dictumst.",
          icon: "mdi-account",
          titlePrimary: false,
          regularprice: "300",
          saleprice: "349",
          pricingTag: true,
          child: [
            {
              btitle: this.context.messages?.PlanFeatures?.Standard?.A001,
              title: this.context.messages?.PlanFeatures?.Standard?.B001,
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: this.context.messages?.PlanFeatures?.Standard?.A002,
              title: this.context.messages?.PlanFeatures?.Standard?.B002,
              icon: "mdi-checkbox-marked-circle",
              tooltipText: this.context.messages?.PlanFeatures?.Standard?.C002,
            },
            {
              btitle: this.context.messages?.PlanFeatures?.Standard?.A003,
              title: this.context.messages?.PlanFeatures?.Standard?.B003,
              icon: "mdi-checkbox-marked-circle",
              tooltipText: this.context.messages?.PlanFeatures?.Standard?.C003,
            },
            {
              btitle: this.context.messages?.PlanFeatures?.Standard?.A004,
              title: this.context.messages?.PlanFeatures?.Standard?.B004,
              icon: "mdi-checkbox-marked-circle",
              tooltipText: this.context.messages?.PlanFeatures?.Standard?.C004,
            },
            {
              btitle: this.context.messages?.PlanFeatures?.Standard?.A005,
              title: this.context.messages?.PlanFeatures?.Standard?.B005,
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: this.context.messages?.PlanFeatures?.Standard?.A006,
              title: this.context.messages?.PlanFeatures?.Standard?.B006,
              icon: "mdi-checkbox-marked-circle",
              // icon: "mdi-close-circle text-danger",
            },
            {
              btitle: this.context.messages?.PlanFeatures?.Standard?.A007,
              title: this.context.messages?.PlanFeatures?.Standard?.B007,
              icon: "mdi-checkbox-marked-circle",
            },
          ],
        },
        {
          id: 3,
          title: "PREMIUM",
          description:
            "Semper urna veal tempus pharetra elit habisse platea dictumst.",
          titlePrimary: false,
          icon: "mdi-account-multiple-plus",
          regularprice: "999",
          saleprice: "999",
          pricingTag: false,
          child: [
            {
              btitle: "Pack Premium",
              title: "Complet",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Aaaaaa",
              title: "Aaaaaa Aaaaaa",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Aaaaaa",
              title: "Aaaaaa Aaaaaa",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Aaaaaa",
              title: "Aaaaaa Aaaaaa",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Aaaaaa",
              title: "Aaaaaa Aaaaaa",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Aaaaaa",
              title: "Aaaaaa Aaaaaa",
              icon: "mdi-checkbox-marked-circle",
            },
            {
              btitle: "Aaaaaa",
              title: "Aaaaaa Aaaaaa",
              icon: "mdi-checkbox-marked-circle",
            },
          ],
        },
      ],
    });
  }

  onChange(e) {
    if (e.target.checked) {
      this.setState({ duration: "yearly" });
    } else {
      this.setState({ duration: "monthly" });
    }
  }

  render() {
    return (
      <section className="section bg-light bg-pricing mt-3" id="pricing">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="title-box text-center">
                <h3 className="title-heading mt-1">
                  {this.context.messages.Titles.S4.H1}
                </h3>
              </div>
            </Col>
          </Row>
          <div
            id="tooltip-container"
            className="d-flex align-items-center justify-content-center mb-1 pb-50"
          >
            <input
              onChange={this.onChange}
              type="checkbox"
              name="cb"
              id="cb"
              className="cb"
            />
            <label className="button" htmlFor="cb">
              <span id="front" className="front">
                {" "}
                {this.context.messages.Monthly}
              </span>
              <span id="back" className="back">
                {this.context.messages.Annually}
              </span>
            </label>

            <Tooltip
              isOpen={this.state.duration === "yearly"}
              target="back"
              placement="right"
            >
              {this.context.messages.Discount}
            </Tooltip>
          </div>
          <Row className="d-flex justify-content-center">
            {/* Render Pricing items */}
            {this.state.pricing.map((item, key) => (
              <div
                key={item.title}
                className={
                  item.title === "STANDARD"
                    ? "pricing-box order-1 order-md-1 order-xl-2 pb-3  mb-4"
                    : item.title === "PREMIUM"
                    ? "pricing-box order-3 order-lg-3 pb-3  mb-4"
                    : "pricing-box order-2 order-xl-1 pb-3 mb-4"
                }
              >
                {item.pricingTag && (
                  <div className="pricing-badge">
                    <span className="badge font-weight-bold">
                      {this.context.messages.Featured}
                    </span>{" "}
                  </div>
                )}
                {/* <i className={"h1 mdi " + item.icon}> </i> */}
                {item.title === "STANDARD" ? (
                  <div className="text-center">
                    <span className="f-20 plan plan-name">
                      <hr
                        className="hr-text-secondary mb-0"
                        data-content={item.title}
                      ></hr>
                    </span>
                  </div>
                ) : item.title === "BASIC" ? (
                  <div className="text-center">
                    <span className="f-20 text-secondary plan plan-name">
                      <hr
                        className="hr-text-secondary mb-0"
                        data-content={item.title}
                      ></hr>
                    </span>
                  </div>
                ) : (
                  <div className="text-center">
                    <span className="f-20 text-primary plan plan-name">
                      <hr
                        className="hr-text-secondary mb-0"
                        data-content={item.title}
                      ></hr>
                    </span>
                  </div>
                )}

                <div className="pricing-plan text-right">
                  <p className="text-center mb-0">
                    {this.state.duration === "yearly" && (
                      <s
                        className={
                          item.title !== "PREMIUM" ? "text-muted" : "med-hide"
                        }
                      >
                        {`${Math.ceil(item.saleprice * 12)} ${
                          this.context.messages.MAD
                        }/`}
                      </s>
                    )}
                    <span
                      className={
                        item.title !== "PREMIUM"
                          ? "plan pl-1 text-warning"
                          : "med-hide plan pl-1 text-warning"
                      }
                    >
                      {`${
                        this.state.duration === "yearly"
                          ? Math.ceil(item.saleprice * 12 - 300)
                          : Math.ceil(item.saleprice)
                      } ${this.context.messages.MAD}`}
                    </span>
                    {/* <span className="plan text-muted mb-0">
                        / {this.context.messages.Month}
                      </span> */}
                  </p>
                </div>
                <div className="mt-1">
                  {item.child.map((linkItem, linkkey) => (
                    <p
                      className="mb-2"
                      id={"tooltip-" + linkkey + "-" + item.title}
                      key={linkkey}
                    >
                      <i
                        className={
                          linkItem.icon.includes("danger")
                            ? `mdi ${linkItem.icon} f-18 mx-2`
                            : item.title === "PREMIUM"
                            ? `mdi ${linkItem.icon} f-18 mx-2 text-success med-hide`
                            : item.title === "BASIC"
                            ? `mdi ${linkItem.icon} f-18 mx-2 text-success`
                            : `mdi ${linkItem.icon} f-18 mx-2 text-success`
                        }
                      ></i>{" "}
                      <span
                        className={
                          item.title !== "PREMIUM" ? "mt-1" : "med-hide mt-1"
                        }
                      >
                        <b>{linkItem.btitle}</b> {linkItem.title}
                      </span>
                      {linkItem?.tooltipText && (
                        <TooltipItem
                          content={linkItem?.tooltipText}
                          id={"tooltip-" + linkkey + "-" + item.title}
                        />
                      )}
                    </p>
                  ))}
                  <div className="mt-1 pt-1 text-center">
                    <Button
                      id={`tooltip-demo-${item.title}`}
                      onClick={() => {
                        scrollToElement("#contact");
                        const formInput = document.getElementById("firstname");
                        if (formInput) {
                          formInput.focus();
                        }
                      }}
                      className={
                        item.title === "PREMIUM"
                          ? `btn med-btn-outline-success btn-rounded disabled d-inline-block`
                          : item.title === "BASIC"
                          ? `btn med-btn-outline-success btn-rounded d-inline-block`
                          : `btn btn-success btn-rounded d-inline-block`
                      }
                    >
                      {this.context.messages.Subscription}
                    </Button>
                    {/* <UncontrolledTooltip
                        className="demo-tooltip"
                        target={`tooltip-demo-${item.title}`}
                        placement="bottom"
                      >
                        {this.context.messages.Titles.S5.H32}
                      </UncontrolledTooltip> */}
                  </div>
                </div>
              </div>
            ))}{" "}
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={12}>
              <div className="text-center">
                <h4 className="text-secondary">
                  {this.context.messages?.PlanFeatures?.Plus?.Title}
                </h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col
              className="d-flex flex-wrap justify-content-start justify-content-sm-center"
              xs={12}
            >
              <div className="py-1">
                <i
                  className={`mdi mdi-checkbox-marked-circle f-18 mx-2 text-primary`}
                ></i>
                <span>
                  <b>{this.context.messages?.PlanFeatures?.Plus?.P1}</b>
                </span>
              </div>
              <div className="py-1">
                <i
                  className={`mdi mdi-checkbox-marked-circle f-18 mx-2 text-primary`}
                ></i>
                <span>
                  <b>{this.context.messages?.PlanFeatures?.Plus?.P2}</b>
                </span>
              </div>
              <div className="py-1">
                <i
                  className={`mdi mdi-checkbox-marked-circle f-18 mx-2 text-primary`}
                ></i>
                <span>
                  <b>{this.context.messages?.PlanFeatures?.Plus?.P3}</b>
                </span>
              </div>
              <div className="py-1">
                <i
                  className={`mdi mdi-checkbox-marked-circle f-18 mx-2 text-primary`}
                ></i>
                <span>
                  <b>{this.context.messages?.PlanFeatures?.Plus?.P4}</b>
                </span>
              </div>
              <div className="py-1">
                <i
                  className={`mdi mdi-checkbox-marked-circle f-18 mx-2 text-primary`}
                ></i>
                <span>
                  <b>{this.context.messages?.PlanFeatures?.Plus?.P5}</b>
                </span>
              </div>
              <div className="py-1">
                <i
                  className={`mdi mdi-checkbox-marked-circle f-18 mx-2 text-primary`}
                ></i>
                <span>
                  <b>{this.context.messages?.PlanFeatures?.Plus?.P6}</b>
                </span>
              </div>
              <div className="py-1">
                <i
                  className={`mdi mdi-checkbox-marked-circle f-18 mx-2 text-primary`}
                ></i>
                <span>
                  <b>{this.context.messages?.PlanFeatures?.Plus?.P7}</b>
                </span>
              </div>
              <div className="py-1">
                <i
                  className={`mdi mdi-checkbox-marked-circle f-18 mx-2 text-primary`}
                ></i>
                <span>
                  <b>{this.context.messages?.PlanFeatures?.Plus?.P8}</b>
                </span>
              </div>
              <div className="py-1">
                <i
                  className={`mdi mdi-checkbox-marked-circle f-18 mx-2 text-primary`}
                ></i>
                <span>
                  <b>{this.context.messages?.PlanFeatures?.Plus?.P9}</b>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <Container className="d-flex justify-content-center">
          <Col lg="7">
            <ModalImage
              small={Preview}
              large={Preview}
              hideZoom={false}
              hideDownload={true}
              imageBackgroundColor="transparent"
              alt=" "
              onClose={this.closeLightbox}
            />
          </Col>
        </Container> */}
        <Container className="pt-4">
          <Row className="justify-content-center">
            <Col lg={9}>
              <div className="text-center">
                <h4 className="text-secondary">
                  {this.context.messages.Titles.S5.H31}{" "}
                  <span className="text-primary">AVOCAT ÉLÉGANT</span>
                </h4>
                <p className="text-muted mt-3"></p>
                <div className="mt-4 pt-2">
                  <Button
                    onClick={() => {
                      scrollToElement("#contact");
                      const formInput = document.getElementById("firstname");
                      if (formInput) {
                        formInput.focus();
                      }
                    }}
                    className="btn font-weight-bolder btn-success rounded-pill mb-2 mr-3 d-inline-block"
                  >
                    {this.context.messages.DemoRequest}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
