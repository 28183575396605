import React, { Component } from "react";
import axios from "axios";
// Importing Section
import Navbar from "../../component/Navbar/NavBar";

import Section from "./Section";
import Services from "../../component/Services";
import Pricing from "../../component/Pricing";
import Team from "../../component/Team";
import Clients from "../../component/Clients";
import Contact from "../../component/Contact";
import Footer from "../../component/Footer/Footer";
import Securité from "../../component/Securité";
import { IntlContext } from "../../utility/context/Internationalization";
import Faqs from "../../component/Faqs";

class Layout1 extends Component {
  static contextType = IntlContext;
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Acceuil" },
        { id: 2, idnm: "services", navheading: "Services" },
        { id: 3, idnm: "securité", navheading: "Securité" },
        { id: 4, idnm: "pricing", navheading: "Forfaits" },
        // { id: 5, idnm: "team", navheading: "Team" },
        { id: 6, idnm: "faqs", navheading: "FAQ" },
        { id: 7, idnm: "contact", navheading: "Contact" },
      ],
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
      fixTop: true,
    };
  }

  componentDidMount() {
    if (document.documentElement.scrollTop) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    }
    //initial request
    axios
      .get("https://api.avocatelegant.com/api/v1/welcome")
      .then(() => {})
      .catch((err) => {});
    //periodic request every 5 min
    this.requestInterval = setInterval(() => {
      axios
        .get("https://api.avocatelegant.com/api/v1/welcome")
        .then(() => {})
        .catch((err) => {});
    }, 5 * 60 * 1000);
    window.addEventListener("scroll", this.scrollNavigation, true);
    this.setState({
      navItems: [
        { id: 1, idnm: "home", navheading: this.context.messages.Home },
        { id: 2, idnm: "services", navheading: this.context.messages.Services },
        { id: 3, idnm: "securité", navheading: this.context.messages.Security },
        {
          id: 4,
          idnm: "pricing",
          navheading: this.context.messages.Subscription,
        },
        // { id: 5, idnm: "team", navheading: this.context.messages.Team },
        { id: 6, idnm: "faqs", navheading: this.context.messages.Faq },
        { id: 7, idnm: "contact", navheading: this.context.messages.Contact },
      ],
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
    clearInterval(this.requestInterval);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 52) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <Navbar
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
          top={this.state.fixTop}
        />

        {/* Importing Section */}
        <Section />

        {/* Importing Service */}
        <Services />

        {/* Importing Securité */}
        <Securité />

        {/* Importing Pricing */}
        <Pricing />

        {/* Importing Team */}
        {/* <Team /> */}

        {/* Importing Clients */}
        {/* <Clients /> */}

        {/* Importing Faqs */}
        <Faqs />

        {/* Importing Contact Us */}
        <Contact />

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}
export default Layout1;
