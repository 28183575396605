import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

import Slider from "react-slick";

import Img1 from "../assets/images/testi-img/img-1.png";
import Img2 from "../assets/images/testi-img/img-2.png";
import Img3 from "../assets/images/testi-img/img-3.png";
import { IntlContext } from "../utility/context/Internationalization";

export default class Clients extends Component {
  static contextType = IntlContext;
  constructor(props) {
    super(props);
    this.state = {
      slideitems: [
        {
          id: 100,
          img: Img1,
          name: "Youssef Aaaa",
          designation: "Lawyer",
          description:
            "C'est excellent logiciel, très agréable et ergonomique. Rien à voir avec les gros éditeurs de logiciels bien trop lourds. Support technique au top, très disponible, sans attente.",
        },
        {
          id: 101,
          img: Img2,
          name: "Mounir Bbbb",
          designation: "Lawyer",
          description:
            "L'application m'apporte un gain de temps et une meilleure gestion centralisée. Enfin une solution tout-en-un pour un tarif très compétitif. Rien à ajouter, c'est une solution complète pour moi.",
        },
        {
          id: 103,
          img: Img3,
          name: "Lahcen Cccc",
          designation: "Lawyer",
          description:
            "J'ai pu synchroniser mes données antérieures avec l'application facilement et sans risques. Vraiment rien avoir avec mon ancien systeme. Merci surtout pour le support, ils sont très gentils et aimables.",
        },
      ],
    };
  }
  componentDidMount() {
    this.setState({
      slideitems: [
        {
          id: 100,
          img: Img1,
          name: "Salim Salim",
          designation: this.context.messages?.Lawyer,
          description: this.context.messages?.Reviews?.R1,
        },
        {
          id: 101,
          img: Img2,
          name: "Ahmed Ahmed",
          designation: this.context.messages?.Lawyer,
          description: this.context.messages?.Reviews?.R2,
        },
        {
          id: 103,
          img: Img3,
          name: "Lahcen Lahcen",
          designation: this.context.messages?.Lawyer,
          description: this.context.messages?.Reviews?.R3,
        },
      ],
    });
  }

  render() {
    var slidesettings = {
      dots: true,
      speed: 300,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      dotsClass: "slick-dots slick-thumb carousel-indicators",
      customPaging: function (i) {
        return (
          <img
            src={process.env.PUBLIC_URL + "/images/img-" + (i + 1) + ".jpg"}
            alt=""
            className=" testi-img img-fluid rounded mx-auto d-block"
          />
        );
      },
    };

    const clientslides = this.state.slideitems.map((slideitem, clientkey) => {
      return (
        <div className="carousel-item" key={slideitem.id}>
          <Row className="align-items-center">
            <Col lg="6">
              <div className="client-box mt-4">
                <h5 className="line-height_1_6">{slideitem.description}</h5>
                <div className="client-icon">
                  <i className="mdi mdi-format-quote-close"></i>
                </div>
                <h5 className="f-18">{slideitem.name} </h5>
                <p className="text-primary mb-0">- {slideitem.designation} </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="text-center mt-4">
                <img src={slideitem.img} className="img-fluid" alt="" />
              </div>
            </Col>
          </Row>
        </div>
      );
    });

    return (
      <React.Fragment>
        <section className="section" id="clients">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="title-box text-center">
                  <h3 className="title-heading mt-1">
                    {this.context.messages.Titles.S6.H1}
                  </h3>
                  <p className="text-muted f-17 mt-3 mb-1">
                    {this.context.messages.Titles.S6.H2}
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mt-1 pt-1">
              <Col lg="12">
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <Slider {...slidesettings}>{clientslides}</Slider>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
