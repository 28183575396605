import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { IntlContext } from "../../utility/context/Internationalization";
import companyLogo from "../../assets/images/moostart.png";
class Footer_link extends Component {
  static contextType = IntlContext;
  render() {
    return (
      // Footer Link start
      <Row>
        <Col lg={12}>
          <div className="text-center">
            {/* <a href="https://moostart.com" target="_blank" rel="noreferrer">
              <img
                className="company-logo"
                src={companyLogo}
                alt="company-logo"
              />
            </a> */}
            <p className="text-muted mb-0">
              {this.context.messages.AllRightReserved} ©{" "}
              {new Date().getFullYear()}
            </p>
          </div>
        </Col>
      </Row>
      //   Footer Link End
    );
  }
}

export default Footer_link;
