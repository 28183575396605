import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Col, Container, Row } from "reactstrap";
import Slider from "react-slick";

//import HomeUrl from "../assets/images/home-border.png";

import Img1 from "../assets/images/users/img-0.jpg";
import Img2 from "../assets/images/users/img-2.png";
import Img0 from "../assets/images/users/img-1.png";
import Img3 from "../assets/images/users/img-3.png";
import Img4 from "../assets/images/users/img-4.png";
import { IntlContext } from "../utility/context/Internationalization";

export default class Contact extends Component {
  static contextType = IntlContext;
  constructor(props) {
    super(props);
    this.state = {
      teams: [
        {
          id: 1,
          img: Img1,
          name: "Mohammed",
          nickname: "Fullstack",
          description:
            "Anterdum maleada fames Integer molestie ante primis nulla facibus felis ulputate platea elit.",
        },
        {
          id: 2,
          img: Img3,
          name: "Ahmed",
          nickname: "Fullstack",
          description:
            "Anterdum maleada fames Integer molestie ante primis nulla facibus felis ulputate platea elit.",
        },
        {
          id: 3,
          img: Img2,
          name: "Fatima",
          nickname: "Team lead",
          description:
            "Anterdum maleada fames Integer molestie ante primis nulla facibus felis ulputate platea elit.",
        },
        {
          id: 4,
          img: Img0,
          name: "Lahcen",
          nickname: "Commercial",
          description:
            "Anterdum maleada fames Integer molestie ante primis nulla facibus felis ulputate platea elit.",
        },
        {
          id: 5,
          img: Img4,
          name: "Youssef",
          nickname: "DevOps",
          description:
            "Anterdum maleada fames Integer molestie ante primis nulla facibus felis ulputate platea elit.",
        },
      ],
    };
  }

  render() {
    var temsettings = {
      autoplay: true,
      dots: true,
      speed: 300,
      infinite: false,
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 1,
    };

    const teamslides = this.state.teams.map((team, teamindex) => {
      return (
        <div className="team-box p-3" key={teamindex}>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="mt-4">
                <h5 className="mt-2">{team.name}</h5>
                <p className="team-badge bg-primary text-white rounded f-14 mt-2">
                  {team.nickname}
                </p>
                <div className="team-social mt-4 pt-2">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <Link to="#" className="text-reset">
                        <i className="mdi mdi-facebook"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" className="text-reset">
                        <i className="mdi mdi-twitter"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" className="text-reset">
                        <i className="mdi mdi-google"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" className="text-reset">
                        <i className="mdi mdi-pinterest"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mt-4">
                <img src={team.img} alt="" className="img-fluid rounded" />
              </div>
            </Col>
          </Row>
        </div>
      );
    });
    return (
      <React.Fragment>
        <section className="section pt-0" id="team">
          <Container>
            <Row>
              <Col lg="12">
                <div className="title-box text-center">
                  <h3 className="title-heading mt-1">
                    {this.context.messages.Titles.S5.H1}
                  </h3>
                  <p className="text-muted f-17 mt-3">
                    {this.context.messages.Titles.S5.H2}
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mt-1 pt-1">
              <Col lg="12">
                <div className="team-carousel">
                  <Slider className="d-none d-md-block" {...temsettings}>
                    {teamslides}
                  </Slider>
                  <Slider
                    className="d-md-none"
                    autoplay={true}
                    dots="true"
                    speed={1500}
                    infinite={true}
                    arrows={false}
                    slidesToScroll={1}
                    slidesToShow={1}
                  >
                    {teamslides}
                  </Slider>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section bg-light bg-cta">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <div className="text-center">
                  <h2>
                    {this.context.messages.Titles.S5.H31}{" "}
                    <span className="text-primary">AVOCAT ÉLÉGANT</span>
                  </h2>
                  <p className="text-muted mt-3">
                    {this.context.messages.Titles.S5.H32}
                  </p>
                  <div className="mt-4 pt-2">
                    <Link
                      to="#contact"
                      className="btn btn-primary btn-round mr-3 btn-rounded"
                    >
                      {this.context.messages.DemoRequest}
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
